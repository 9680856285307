import React from 'react';
import defaultUser from '../assets/images/default-user.png';

const TopBar = () => {
    return (
        <div className="topbar">
            <div className="user-info">
                <img src={defaultUser} alt="User" className="user-picture" />
                <span className="user-name">{"kirk"}</span>
            </div>
            <button className="logout-button">
                Logout
            </button>
        </div>
    );
};

export default TopBar;