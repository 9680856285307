// userReducer.js

// Initial State
const userInitialState = {
    currentUser: null,
    isLoggedIn: false,
    picture: "default-user.png",

  };
  
  // Reducer Function
  const userReducer = (state = userInitialState, action) => {
    switch (action.type) {
      case 'SET_USER':
        return {
          ...state,
          currentUser: action.payload,
          isLoggedIn: true,
        };
      case 'CLEAR_USER':
        return {
          ...state,
          currentUser: null,
          isLoggedIn: false,
        };
      default:
        return state;
    }
  };
  
  export default userReducer;