import { Provider } from 'react-redux';
import './App.css';
import '@aws-amplify/ui-react/styles.css';
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './storage/reducers/user';
import Main from './pages/Main/Main'; // Import the Homepage component

function App() {
  const store = configureStore({ 
    reducer: {
      user: userReducer
    } 
  });

  return (
    <Provider store={store}>
      <div className="App">
        <Main />
      </div>
    </Provider>
  );
}

export default App;
