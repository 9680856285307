import React from 'react';
import { connect } from 'react-redux';
import Home from '../Home/Home';
import Login from '../Login/Login';

const Main = ({currentUser, isLoggedIn}) => {
    if (!isLoggedIn) {
        return (
            <div>
                <Login />
            </div>
        );
    }
    else {
        return (
            <div>
                <Home />
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
      currentUser: state.currentUser,
      isLoggedIn: state.isLoggedIn
    };
  };

export default connect(
    mapStateToProps
)(Main);
