import React from 'react';
import Topbar from '../../components/TopBar'; // Import the Topbar component

const Home = () => {
    return (
        <div>
            <Topbar /> {/* Move the Topbar component to the top */}
            <h1>Welcome to Personal Sommelier</h1>
            <p>Discover and explore the world of wines with us!</p>
            {/* Add more content and components as needed */}
        </div>
    );
};


export default Home;
